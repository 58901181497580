import React from 'react'
import Action from './Action'

// TODO merge with Banner
const News = ({title, content, action}) => {
    return <section id="two">
            <div className="inner">
                <header className="major">
                    <h2>{title}</h2>
                </header>
                <div className="content" dangerouslySetInnerHTML={{__html: content}}/>
                {
                    action && <Action {...action}/>
                }
            </div>
        </section>
}

export default News

