import pic01 from '../assets/images/produkty.jpg'
import pic02 from '../assets/images/technologia.jpg'
import pic03 from '../assets/images/inziniering.jpg'
import pic04 from '../assets/images/galeria.jpg'
import caruselBanner from '../assets/images/banner3.jpg'


const contentEnglish = {
  caruselItems: [
    {
      title: 'Cutting edge performance',
      description: '',
      button: 'Find out more',
      image: caruselBanner,
    },
  ],
  tiles: [
    {
      title: 'Products',
      description: 'Ipsum dolor sit amet',
      to: '/produkty',
      picture: pic01,
    },
    {
      title: 'Technology',
      description: 'Ipsum dolor sit amet',
      to: '/technologia',
      picture: pic02,
    },
    {
      title: 'Engineering',
      description: 'Ipsum dolor sit amet',
      to: '/inziniering',
      picture: pic03,
    },
    {
      title: 'Gallery',
      description: 'Ipsum dolor sit amet',
      to: '/galeria',
      picture: pic04,
    },
  ],
  about: {
    title: 'About company',
    content: `The company FRT – Ferencz Racing Technology was established in 1989 in Košice, Slovakia. The production of the company at the beginning was based upon the correct short-run production of the engineering parts, the manufacturing and the maintenance of the engines and technologies and at least the custom manufacturing of the parts for motorsport and for the construction of the racing cars. 
        </br>
        The development of the personal and technologic equipment of the company had headed to the development, the manufacturing, the adjustment and the servicing of the suspensions(Shock absorbers, Suspensions, Dämpfer) FRT and other major makers Öhlins, Sachs, Penske, Boss, Reiger, Donerre, Proflex, Fox, King... , used in many motorsport events of Dakar series, CCR, Rally, Okruhy-circuit, Octavia Cup, Kopce-Hillclimb and in many others.`,
    action: {
      to: '/predstavenie',
      label: 'Viac informácii',
    },
  },
  contactInfo: [
    {
      icon: 'home',
      title: 'Address',
      content: `<span>
            FRT - Ferencz Racing Technology,<br />
            Jozef Ferencz,<br />
            Južná trieda 82,<br />
            040 01 Košice<br/>
            Slovakia</span>`,
    },
    {
      icon: 'phone',
      title: 'Phone',
      content: '<a href="tel:+421 905 302 938">+421 905 302 938</a>',
    },
    {
      icon: 'envelope',
      title: 'Email',
      content: '<a href="mailto:frt@frt.sk">frt@frt.sk</a>',
    },
  ],
  mapButtonLabel: 'Open in maps',
}

const contentSlovak = {
  caruselItems: [
    {
      title: 'Výkon na hranici možností.',
      description: '',
      button: 'Zistiť viac',
      image: caruselBanner,
    },
  ],
  tiles: [
    {
      title: 'Produkty',
      description: 'Ipsum dolor sit amet',
      to: '/produkty',
      picture: pic01,
    },
    {
      title: 'Technológia',
      description: 'Ipsum dolor sit amet',
      to: '/technologia',
      picture: pic02,
    },
    {
      title: 'Inžiniering',
      description: 'Ipsum dolor sit amet',
      to: '/inziniering',
      picture: pic03,
    },
    {
      title: 'Galéria',
      description: 'Ipsum dolor sit amet',
      to: '/galeria',
      picture: pic04,
    },
  ],
  about: {
    title: 'O firme',
    content: `Firma FRT – Ferencz Racing Technology vznikla v roku 1989 v Košiciach. Podnikanie firmy v jej začiatkoch bolo zamerané na presnú malosériovú výrobu strojárskych súčiastok, výrobu a údržbu strojov a technológií a zákazkovú výrobu súčiastok používaných v motoristickom športe a stavbe súťažných áut. 
        <br/>
        Personálne obsadenie a technologické vybavenie sa postupom času zameralo na vývoj, výrobu, nastavenie a servis tlmičov (Shock absorbers, Suspensions, Dämpfer) FRT a iných popredných svetových značiek Öhlins, Sachs, Penske, Boss, Reiger, Donerre, Proflex, Fox, King... , ktoré sú používané v mnohých motoristických disciplínach Dakar series, CCR-Crosscountry rally, Rally, Okruhy-circuit, Octavia Cup, Kopce-Hillclimb a iných.`,
    action: {
      to: '/predstavenie',
      label: 'Viac informácii',
    },
  },
  contactInfo: [
    {
      icon: 'home',
      title: 'Adresa',
      content: `<span>
            FRT - Ferencz Racing Technology,<br />
            Jozef Ferencz,<br />
            Južná trieda 82,<br />
            040 01 Košice<br/>
            Slovakia</span>`,
    },
    {
      icon: 'phone',
      title: 'Telefón',
      content: '<a href="tel:+421 905 302 938">+421 905 302 938</a>',
    },
    {
      icon: 'envelope',
      title: 'Email',
      content: '<a href="mailto:frt@frt.sk">frt@frt.sk</a>',
    },
  ],
  mapButtonLabel: 'Otvoriť v mapách',
}

const contentMagyar = {
    caruselItems: [
        {
            title: 'A legmodernebb teljesítmény',
            description: '',
            button: 'többet látni',
            image: caruselBanner,
        },
    ],
    tiles: [
        {
            title: 'Termékek',
            description: 'Ipsum dolor sit amet',
            to: '/produkty',
            picture: pic01,
        },
        {
            title: 'Technológia',
            description: 'Ipsum dolor sit amet',
            to: '/technologia',
            picture: pic02,
        },
        {
            title: 'Mérnöki',
            description: 'Ipsum dolor sit amet',
            to: '/inziniering',
            picture: pic03,
        },
        {
            title: 'Galéria',
            description: 'Ipsum dolor sit amet',
            to: '/galeria',
            picture: pic04,
        },
    ],
    about: {
        title: 'A Cégről',
        content: `AZ FRT, a Ferencz Racing Technology 1989-ben alakult Kassán. A cég kis szériájú, precíziós gépalkatrészek gyártásával kezdte, egyedi megrendelések alapján gyártott pl. hidraulikus berendezésekhez és nyomdagépekhez alkatrészeket. Figyelem, hiba a sorban, vessző kell a cégtulajdonos szó után! A cégtulajdonos, autóversenyzői múltja miatt, már kezdetben is fontosnak tartotta, hogy cége versnyautó alkatrészek gyártását is rugalmasan el tudja végezni. 
        </br>
        Az évek alatt fokozatosan háttérbe szorult a gépalkatrészek gyártása, közben a cég szinte teljes mértékben a versenyautó alkatrészek gyártására specializálódott. Ezen belül is különösen fókuszálva a futóművekre, kifejezetten a lengéscsillapítók gyártására, azok fejlesztésére, szervizelésére, és a futóműdinamikai beállítások háttérmunkáinak elvégzésére. 
        </br>
        A saját gyártmány, az FRT mellett a cég foglalkozik több világszerte ismert márkájú lengéscsillapító javításával, beállításával, alkatrész ellátásával, mint pl. Öhlins, Sachs, Penske, Boss, Reiger, Proflex, Donerre, Fox, King.., melyeket jól ismerhetünk, a raliból, terep-raliból, pályaversenyekről, vagy az off-road versenyekről... `,
        action: {
            to: '/predstavenie',
            label: 'További információ'
        }
    },
    contactInfo: [
        {
            icon: 'home',
            title: 'Cím',
            content: `<span>
            FRT - Ferencz Racing Technology,<br />
            Jozef Ferencz,<br />
            Južná trieda 82,<br />
            040 01 Košice<br/>
            Slovakia</span>`,
        },
        {
            icon: 'phone',
            title: 'Telefon',
            content: '<span>+421 905 302 938</span>',
        },
        {
            icon: 'envelope',
            title: 'Email',
            content: '<a href="mailto:frt@frt.sk">frt@frt.sk</a>',
        },
    ]
}

const main = {
    'sk': contentSlovak,
    'en': contentEnglish,
    'hu': contentMagyar,
}

export default main