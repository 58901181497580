import React from 'react'

import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import { CaruselItem } from './_components/CaruselItem';

const SLIDER_SETTINGS = {
    autoplay: 3000, 
    infinite: true,
    disabled: false,
}

const Carusel = ({items}) => {
    
    if (items.length === 0) {
        return null
    }

    if (items.length === 1) {
        return <div className="slider"><CaruselItem item={items[0]} key={0} /></div>
    }

    return <Slider {...SLIDER_SETTINGS}>
            {items.map((item, index) => <CaruselItem item={item} key={index}/>)}
    </Slider> 
}

export default Carusel




