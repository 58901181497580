import React from 'react'
import PropTypes from 'prop-types'

import { LoadScript, GoogleMap, Marker } from '@react-google-maps/api'
import mapStyles from './mapStyles'
import { Overlay } from './_components/Overlay';

const API_KEY = `AIzaSyASTbnA75kaRsY97WdnW4n0z308BFN8K_o`

const options = {
  disableDefaultUI: true,
  draggable: false,
  mapTypeControl: false,
  styles: mapStyles,
}

const GMap = ({zoom, link, label, coordinates}) => {
return (
  <Overlay link={link} label={label}>
    <LoadScript
      id="script-loader"
      googleMapsApiKey={API_KEY}
      language={'en'}
      region={'EN'}
      version={'weekly'}
      libraries={[]}
      loadingElement={<div>Loading...</div>}
      preventGoogleFonts
    >
      <GoogleMap
        id="marker-example"
        mapContainerStyle={{
          height: '400px',
          width: '100%',
        }}
        zoom={zoom}
        options={options}
        center={coordinates}
      >
        <Marker
          options={{
            title: '',
            position: coordinates,
          }}
        />
      </GoogleMap>
    </LoadScript>
  </Overlay>
)
}

GMap.defaultProps= {
  zoom: 16,
}

GMap.propTypes = {
  coordinates: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }).isRequired,
  zoom: PropTypes.number
}

export default GMap