import React from 'react'
import styles from './caruselItem.module.scss'

const getStyles = image => {
    return {
        backgroundImage: `url('${image}')`,
    }
}

export const ImageWrapper = ({ children, image }) => (
         <div className={styles.wrapper} style={getStyles(image)}>
           {children}
         </div>
       )