import React from 'react'
import MainPage from '../layouts/MainPage';


import { graphql } from 'gatsby';
import { I18n } from 'react-i18next';
import { withI18next } from 'gatsby-plugin-i18next';
import { getContentTranslation } from '../helpers';

import content from '../content/main'
import contact from '../content/contact'

const HomeIndex = () => <I18n>
            {(t, {i18n}) => {
              const {language} = i18n
             if (typeof language === 'undefined') {
                return null
              }
    return <MainPage {...getContentTranslation(content, language)} contact={getContentTranslation(contact, language)} />
            }
          }
</I18n>

export default withI18next()(HomeIndex)

export const query = graphql`
query($lng: String!) {
  locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
    ...TranslationFragment
  }
}
`