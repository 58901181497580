import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby-plugin-i18next'

const Tile = ({title, description, to, picture}) => 
    <article style={{backgroundImage: `url(${picture})`}}>
    <header className="major">
        <h3>{title}</h3>
        <p>{description}</p>
    </header>
    <Link to={to} className="link primary" aria-label={title}></Link>
</article>

Tile.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    picture: PropTypes.string.isRequired,
}

export default Tile