import React, { Fragment } from 'react'
import Info from './_components/Info';
import GoogleMap from '../GoogleMap';

const Contact = ({ contactInfo, coordinates, link, mapButtonLabel}) => <Fragment>
     
        <Info contactInfo={contactInfo}/>
        <GoogleMap
            coordinates={coordinates}
            link={link}
            label={mapButtonLabel}
        /> 
</Fragment> 


export default Contact