import React from 'react'
import styles from './caruselItem.module.scss'

export const Content = ({ title, description }) => (
         <div className={styles['sliderContent']}>
           {title && <h1>{title}</h1>}
           {description && <p>{description}</p>}

           {/* <button>{item.button}</button> */}
         </div>
       )