import React from 'react'


import Tile from './Tile'
const Tiles = ({ tiles }) => {
    return tiles.length > 0 &&
            <section id="one" className="tiles">
                {
                    tiles.map((tileProps, i) => <Tile {...tileProps} key={i}/>)
                }
            </section>
}
export default Tiles