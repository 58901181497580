import React  from 'react';
import styles from './overlay.module.scss'

export const Overlay = ({ children, link, label }) => (
  <a href={link} target="_blank" rel="noopener noreferrer">
           <div className={styles['overlay']}>
             <div className={styles['container']}>
               <button>{label}</button>
             </div>

             <div className={styles['children']}>{children}</div>
           </div>
         </a>
       )