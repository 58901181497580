import React, { Fragment } from 'react'
import QrCodeWrapper from '../../../QrCode';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'

const capitalize = str => `${str.charAt(0).toUpperCase()}${str.slice(1)}`

const iconName = icon => `fa${capitalize(icon)}`

const importedIcons = {
    faHome, faPhone, faEnvelope
}

const getIcon = icon => importedIcons[iconName(icon)]


const InfoSection = ({title, icon, content, index}) => {
    return <div class="col-4 contact-method">
            <div>
                <span className={`icon alt`}>
                    <FontAwesomeIcon icon={getIcon(icon)} />
                </span>
                <h3>{title}</h3>
            </div>
            <div dangerouslySetInnerHTML={{__html: content}} />        
    </div>
}

export default InfoSection