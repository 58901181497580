import React from 'react'
import Layout from '../layouts/MainLayout'
import Banner from '../components/Banner'
import Tiles from '../components/Tiles';
import News from '../components/News';
import Contact from '../components/Contact'
import Carusel from '../components/Carusel';

import { MAP_COORDINATES, MAPS_LINK } from '../constants';

const news = [{
        title: "Massa libero",
        content: "<p>Nullam et orci eu lorem consequat tincidunt vivamus et sagittis libero. Mauris aliquet magna magna sed nunc rhoncus pharetra. Pellentesque condimentum sem. In efficitur ligula tate urna. Maecenas laoreet massa vel lacinia pellentesque lorem ipsum dolor. Nullam et orci eu lorem consequat tincidunt. Vivamus et sagittis libero. Mauris aliquet magna magna sed nunc rhoncus amet pharetra et feugiat tempus.</p>",
        action: {
                label: "see more",
                to: "/"
        }
}]

const MainPage = ({
  caruselItems,
  tiles,
  about,
  contactInfo,
  contact,
  mapButtonLabel,
}) => (
  <Layout>
    <Carusel items={caruselItems} />
    <News {...news[0]} />
    <Tiles tiles={tiles} />
    <Banner {...about} />
    <Contact
      contactInfo={contactInfo}
      contact={contact}
      coordinates={MAP_COORDINATES}
      link={MAPS_LINK}
      mapButtonLabel={mapButtonLabel}
    />
  </Layout>
)

export default MainPage


