
const contactEnglish =  {
    name: "Name",  
    message: "Message",  
    email: "Email",
    send: "Send", 
    agreement_message: "Súhlasím so spracovaním emailovej adresy.",
  }

  const contactSlovak =  {
    name: "Meno",  
    message: "Správa",  
    email: "Email",
    send: "Odoslať",
    agreement_message: "I agree to process my email adress.",
  }

  const contactMagyar = {
    name: "Név",  
    message: "Levél szövege:",  
    email: "Email",
    send: "Küldés", 
    agreement_message: "I agree to process my email adress.",
  }

  const contact = {
      'sk': contactSlovak,
      'en': contactEnglish,
      'hu': contactMagyar
  }

  export default contact