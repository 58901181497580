import React, {Fragment} from 'react'
import InfoSection from './InfoSection';
import QrCodeWrapper from '../../../QrCode';

const Info = ({contactInfo}) => {
    return <section><div class="inner grid-wrapper">
{/* <div className="right"><QrCodeWrapper url={'https://frt.demecko.com/'}/></div>      */}
    {
    //     <section>
    //     </section>
            contactInfo.map((info, i) => <InfoSection {...info} key={i} index={i}/>)
        }
    </div>
    </section>
}


export default Info


